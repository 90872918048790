global.$ = global.jQuery = require('jquery');
require('jquery-unveil');
require('slick-carousel');
require('swiper/js/swiper');
require('bootstrap-3');
require('bootstrap-material-design/dist/js/ripples.min');
require('bootstrap-material-design/dist/js/material.min');
$.material.init();

require('bootstrap-3/dist/css/bootstrap.min.css');
require('bootstrap-material-design/dist/css/ripples.min.css');
require('bootstrap-material-design/dist/css/material.min.css');
require('slick-carousel/slick/slick.css');
require('swiper/css/swiper.min.css');